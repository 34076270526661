<template>
  <div class="bigBox">
    <div class="multyName">{{ graphicDetail.multyName }}</div>
    <div v-html="ImgSizeLimit(graphicDetail.multyNote)" />
    <div v-for="(item, index) in graphiclists" :key="index" class="graphicsItem">
      <!-- 动态添加的知识产品 -->
      <div v-if="item.itmType == 'knowproduct'" class="knowproduct" @click="goPage(item)">
        <img class="knowproductImg" :src="item.itmImg">
        <div class="textContent">
          <div class="titleText">{{ item.itmName }}</div>
          <div class="tipsText">{{ item.itmNote }}</div>
          <!-- <div class="priceBox">
            <span class="symbol">￥</span>
            <span class="num">{{ item.itmUrl }}</span>
          </div> -->
        </div>
      </div>
      <!-- 动态添加的富文本 -->
      <div v-if="item.itmType == 'richtext'" v-html="ImgSizeLimit(item.itmNote)" />
      <!-- 动态添加的视频 -->
      <div v-if="item.itmType == 'video'" class="videoBox">
        <video class="video" :src="item.itmUrl" controls />
        <div>{{ item.itmName }}</div>
        <div>{{ item.itmNote }}</div>
      </div>
      <!-- 动态添加的讲师 -->
      <div v-if="item.itmType == 'tecaher'" class="tecaherBox">
        <img class="tecaherImg" :src="item.itmImg">
        <div class="teacherDetail">
          <div class="teacherName">
            <span>{{ item.itmName }}</span>
          </div>
          <div class="teacherText">
            <span>{{ item.itmNote }}</span>
          </div>
        </div>
      </div>
      <!-- 表单内容 -->
      <div v-for="(i, j) in formList" v-show="item.itmType == 'form'" :key="`${j + 100}`" class="formItem">
        <div>
          <!-- 标题 -->
          <div v-if="i.formFieldName != '我已阅读并同意'
            " class="itemTitle">
            <span v-if="i.formIsBlank == '1'" class="require">*</span>
            {{
              i.formFieldName == "单选" ||
              i.formFieldName == "多选" ||
              i.formFieldName == "下拉菜单" ||
              i.formFieldName == "上传图片" ||
              i.formFieldName == "文本框" ||
              i.formFieldName == "签名"
              ? i.formFieldNote || i.formFieldName
              : i.formFieldName
            }}
          </div>
          <!-- <div class="itemTitle" v-if="i.formFieldName == '性别'">性别</div>
          <div class="itemTitle" v-if="i.formFieldName == '地址'">地址</div>
          <div class="itemTitle" v-if="i.formFieldName != '我已阅读并同意' && i.formFieldName !== '性别' && i.formFieldName !== '地址' && i.formFieldType == 'text'">
            {{ i.formFieldName }}
          </div>
          <div class="itemTitle" v-if="i.formFieldName != '我已阅读并同意' && i.formFieldName !== '地址' && i.formFieldType == 'select' || i.formFieldType == 'checkbox' || i.formFieldType == 'radio'">
            {{ i.formFieldNote }}
          </div> -->
          <!-- 输入框 -->
          <div v-if="i.formFieldType == 'text' && i.formFieldName != '我已阅读并同意'
              " class="contentBox">
            <div class="inputWrap">
              <el-input v-model="i.formInputVal" class="input" :placeholder="i.formFieldNote ? i.formFieldNote : ''" />
            </div>
          </div>
          <!-- 单选框 -->
          <div v-if="i.formFieldType == 'radio' &&
            i.subjson &&
            i.formFieldName != '性别'
            " class="contentBox">
            <el-radio-group v-model="i.formInputVal" @change="radioChange($event, JSON.parse(i.subjson), j)">
              <el-radio v-for="(items, indexs) in JSON.parse(i.subjson)" :key="`${indexs + 1000}`"
                :label="items.formFieldName">{{ items.formFieldName }}</el-radio>
            </el-radio-group>
          </div>
          <!-- 性别 -->
          <div v-if="i.formFieldName == '性别'" class="contentBox">
            <el-radio-group v-model="i.formInputVal">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </div>
          <!-- 多选框 -->
          <div v-if="i.formFieldType == 'checkbox' && i.subjson && i.subjson
              ? i.subjson.length > 0
              : false
            " class="contentBox">
            <el-checkbox-group v-model="i.arr" @change="checkboxChange($event, JSON.parse(i.subjson), j)">
              <el-checkbox v-for="(items, indexs) in JSON.parse(i.subjson)" :key="`${indexs + 20000}`"
                :label="items.formFieldName" />
            </el-checkbox-group>
          </div>
          <!-- 下拉选择框 -->
          <div v-if="i.formFieldType == 'select' &&
            i.subjson &&
            i.formFieldName != '我已阅读并同意'
            " class="contentBox">
            <el-select v-model="i.formInputVal" class="select" @change="selectChange($event, JSON.parse(i.subjson), j)">
              <el-option v-for="(items, indexs) in JSON.parse(i.subjson)" :key="`${indexs + 10000}`"
                :label="items.formFieldName" :value="items.formFieldName" />
            </el-select>
          </div>
          <!-- 选择地址 -->
          <div v-if="i.formFieldName == '地址'" class="contentBox">
            <el-row>
              <el-col :span="8">
                <el-select v-model="i.province" style="margin-right: 10px;" @change="(e) => { provinceChange(e, j) }">
                  <el-option v-for="province in provinceList" :key="province.id" :label="province.name"
                    :value="province.name" />
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="i.city" style="margin-right: 10px;" @change="(e) => { cityChange(e, j) }">
                  <el-option v-for="city in cityList" :key="city.id" :label="city.name" :value="city.name" />
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="item.section" @change="(e) => { sectionChange(e, j) }">
                  <el-option v-for="section in sectionList" :key="section.id" :label="section.name"
                    :value="section.name" />
                </el-select>
              </el-col>
            </el-row>
          </div>
          <!-- 阅读并同意 -->
          <div v-if="i.formFieldName == '我已阅读并同意'" id="clause" class="contentBox">
            <el-checkbox v-model="checked" style="margin-bottom: 10px">我已阅读并同意</el-checkbox>
            <el-input v-model="i.formFieldNote" disabled type="textarea" :rows="5" placeholder="请输入内容" />
          </div>
          <!-- 上传图片 -->
          <div v-if="i.formFieldType == 'img'" class="contentBox">
            <el-upload :ref="`formBackImg${index}`" :show-file-list="false" name="upload" :action="imgAction()"
              :headers="Domainjwt" :on-success="(res, file, fileList) => {
                  thumbnailSuccess(
                    `formBackImg${index}`,
                    i,
                    res,
                    file,
                    fileList
                  );
                }
                " :before-upload="imgurlBeforeUpload">
              <div class="dis_flex">
                <div class="avatar-uploader-sheji">
                  <img v-if="i.formInputVal" :src="i.formInputVal" class="avatar">
                  <div v-else class="icon">
                    <i class="el-icon-plus avatar-uploader-icon" />
                    <div>{{ i.formFieldNote }}</div>
                  </div>
                </div>
                <div class="m-l-10">
                  <div class="uploadBut">
                    <el-button size="small" type="success">点击上传</el-button>
                  </div>
                  <div class="uploadTip">（仅支持图片格式，且不超过3M）</div>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div v-if="formList && formList.length > 0" class="submitBox">
      <div class="submitBtn" @click="submit">提交</div>
      <!-- <div class="shareBox">
        <div class="iconfont icon-a-zu334"></div>
        <div class="text" @click="openShareShow">分享</div>
      </div> -->
      <el-card v-if="shareShow" class="shareDialog">
        <div slot="header" class="clearfix">
          <span>分享至</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="openShareShow">关闭</el-button>
        </div>
        <div class="shareContent">
          <div class="shareImg">
            <div class="shareImgItem" @click="toShare('https://qzone.qq.com/')">
              <img src="@/assets/img/Home/Group2853.png">
            </div>
            <div class="shareImgItem" @click="openSharePopup">
              <img src="@/assets/img/Home/MaskGroup.png">
            </div>
            <div class="shareImgItem"
              @click="toShare('https://weibo.com/newlogin?tabtype=weibo&gid=102803&openLoginLayer=0&url=https%3A%2F%2Fweibo.com%2F')">
              <img src="@/assets/img/Home/MaskGroup-1.png">
            </div>
            <div class="shareImgItem" @click="openSharePopup">
              <img src="@/assets/img/Home/Group2854.png">
            </div>
          </div>
          <div class="urlBox">
            <el-input v-model="shareUrl" class="urlInput" size="mini" />
            <el-button type="primary" class="urlBtn" size="mini" @click="copyUrl">复制链接</el-button>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 登录弹框 -->
    <el-dialog class="loginDialog" :close-on-click-modal="false" title="" :visible.sync="showLogin"
      :before-close="handleClose">
      <new-login v-if="showLogin" :key="newloginKey" @toHome="toHome" @toRegister="toRegister" @showForgot="showForgot" />
    </el-dialog>
    <!-- 注册弹框 -->
    <el-dialog :close-on-click-modal="false" class="loginDialog" title="" :visible.sync="showRegister"
      :before-close="handleCloseRegister">
      <new-register v-if="showRegister" :key="registerKey" @handleShowLogin="handleShowLogin" />
    </el-dialog>
    <!-- 忘记密码弹框 -->
    <el-dialog class="loginDialog" title="" :close-on-click-modal="false" width="22%" :visible.sync="showForgotDialog"
      :before-close="handleCloseForgot">
      <for-got :key="forgotKey" @dealForgot="dealForgot" />
    </el-dialog>

    <el-dialog :visible.sync="sharePopup" width="30%" :before-close="closeSharePopup">
      <div class="qrcode_box">
        <qriously class="qrcode" :value="mUrl" :size="92" />
        <div>扫描二维码，点击右上角...按钮分享</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMultyDetailById,
  getFormById,
  selectAllProvice,
  saveInputFormVal,
  getMultyDetailForpage,
  getDnsOneByTenantId
} from '@/api/home.js'
import address from '@/assets/js/address.js'
import { getInfo, getDomain, getDomainToken } from '@/api/cookies'
import newLogin from '@/views/login/newLogin.vue'
import newRegister from '@/views/login/newRegister.vue'
import forGot from '@/views/login/forget.vue'
export default {
  components: {
    newLogin,
    newRegister,
    forGot
  },
  data() {
    return {
      graphiclists: [], // 图文页内容列表
      graphicDetail: {}, // 图文页详情
      formList: [], // 表单详情
      cities: [], // 市列表
      provinceList: [], // 省列表
      cityList: [],
      sectionList: [],
      checked: false,
      shareShow: false,
      recordId: null, // 图文页ID
      formId: null, // 推广表单ID
      accessId: null, // 用户访问时返回的访问编码
      userId: null, // 机构ID
      userInfo: null,
      Domainjwt: {
        Domainjwt: getDomainToken()
      },
      shareUrl: null, // 分享链接
      shareFrom: null, // 分享来源
      imgAccessId: null, // 推广图文页访问时记录的ID
      formAccessId: null, // 推广表单提交时用的ID
      clause: null,
      showLogin: false,
      forgotKey: 0,
      newloginKey: 0,
      registerKey: 0,
      showRegister: false,
      showForgotDialog: false,
      mUrl: '',
      sharePopup: false,
      formThanksNote: '' // 提交成功的提示
    }
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        const url = this.shareUrl + ',' + this.userInfo.id
        this.shareUrl = url
      }
    }
  },
  created() {
    this.shareFrom = this.$route.query.shareFrom
    this.getMultyDetailForpage()
    this.recordId = this.$route.query.id
    this.userId = this.$route.query.userId
    this.getMultyDetailById()
    this.provinceList = address
    this.userInfo = getInfo()
    this.shareUrl = window.location.href
    this.getDnsOneByTenantId()
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  methods: {
    beforeunloadHandler(e) {
      this.timesClosePage()
    },
    copyUrl() {
      /* 点击复制 */
      const oInput = document.createElement('input')
      oInput.value = `${this.shareUrl}?shareFrom=${this.userInfo ? this.userInfo.id : null}`
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      oInput.remove()
      this.$message('复制成功')
    },
    /* 分享查看获取信息 */
    getMultyDetailForpage() {
      const data = {
        recordId: this.recordId ? this.recordId : null,
        shareFrom: this.shareFrom,
        accUrl: window.location.href,
        userid: this.userInfo ? this.userInfo.id : null
      }
      getMultyDetailForpage(data)
    },
    /* 图文页内容 */
    getMultyDetailById() {
      const data = {
        recordId: this.recordId
      }
      getMultyDetailById(data).then((res) => {
        this.graphiclists = eval(res.msg.itmJson)
        this.imgAccessId = res.msg.accessId
        this.graphicDetail = res.msg
        this.accessId = res.msg.accessId
        this.graphiclists.forEach((item) => {
          if (item.itmType == 'form') {
            this.getFormById(item.objectId)
            this.formId = item.objectId
          }
        })
      })
    },

    /* 查询表单详情 */
    getFormById(formId) {
      const data = {
        formId
      }
      getFormById(data).then((res) => {
        this.accessId = res.accessId
        this.formList = eval(res.data.fieldJson)
        this.formList.map((item) => {
          if (item.formFieldType == 'select') {
            item.pickerVal = 0
          }
          if (item.formFieldType == 'checkbox') {
            /* item.formInputVal = [] */
            this.$set(item, 'arr', [])
          }
        })
        this.formThanksNote = res.data.formThanksNote || '提交成功'
        this.$forceUpdate()
      })
    },
    /* 选择省份 */
    provinceChange(val, index) {
      this.$set(this.formList[index], 'city', '')
      this.cityList = []
      this.cityList = this.provinceList.filter(item => {
        return item.name == val
      })[0].children
    },
    cityChange(val, index) {
      this.$set(this.formList[index], 'section', '')
      this.sectionList = []
      this.sectionList = this.cityList.filter(item => {
        return item.name == val
      })[0].children
      if (this.sectionList.length == 0) {
        this.formList[index].formInputVal =
          this.formList[index].province +
          '-' +
          val
      }
    },
    sectionChange(val, index) {
      this.formList[index].formInputVal =
        this.formList[index].province +
        '-' +
        this.formList[index].city +
        '-' +
        val
    },
    /* 单选选择 */
    radioChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return item.formFieldName == val
      })
      this.formList[index].formFieldId = obj[0].id
    },
    /* 多选选择 */
    checkboxChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return val.includes(item.formFieldName)
      })
      this.formList[index].formInputVal = obj
    },
    /* 下拉选择 */
    selectChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return item.formFieldName == val
      })
      this.formList[index].formFieldId = obj[0].id
    },
    /* 点击分享 */
    openShareShow() {
      this.shareShow = !this.shareShow
    },
    /* 图片上传成功回调 */
    thumbnailSuccess(ref, i, res) {
      if (res.isSuccess) {
        this.$set(i, 'formInputVal', res.path)
      } else {
        this.$message.error(res.error)
        Vue.prototype.imgClearn(ref, this)
      }
    },
    /* 图片上传之前 */
    imgurlBeforeUpload(file, size) {
      const isRightSize = file.size / 1024 / 1024 < (size || 3)
      if (!isRightSize) {
        this.$message.error(`文件大小超过 ${size || 3}MB`)
      }
      return isRightSize
    },
    validateForm() {
      const list = this.formList.filter((d) => d.formIsBlank == '1')
      return list.some((d) => {
      

        if (
          (!d.formInputVal && d.formFieldName != '我已阅读并同意') ||
          (d.formFieldType == 'checkbox' && d.formInputVal.length == 0)
        ) {
          this.$message({
            type: 'warning',
            message: '请填写完全部内容后再提交'
          })
          return true
        } else {
          return false
        }
      })
    },
    /* 提交 */
    submit() {
      if (this.validateForm()) {
        return
      }
      // if (!this.userInfo) {
      //   // Vue.prototype.goLoginView(true);
      //   this.showLogin = true
      //   return;
      // }
      const clause = document.querySelector('#clause')
      if ((clause && this.checked) || !clause) {
        const data = {
          jsonVal: [],
          formId: this.formId,
          accessId: this.accessId,
          userid: this.userInfo ? this.userInfo.id : null
        }
        this.formList.map((item) => {
          if (item.formFieldType == 'checkbox' && item.formInputVal) {
            item.formInputVal.forEach((item) => {
              const obj = {
                formFieldId: item.id,
                formInputVal: item.formFieldName
              }
              data.jsonVal.push(obj)
            })
          } else if (item.formFieldType == 'radio' && item.formFieldName != '性别' || item.formFieldType == 'select') {
            if (item.formFieldName == '地址') {
              data.jsonVal.push({
                formFieldId: item.id,
                formInputVal: item.province
                  ? item.province + '-' + item.city ||
                  '' + (item.section ? '-' + item.section : '')
                  : ''
              })
            } else {
              const obj = {
                formFieldId: item.formFieldId,
                formInputVal: item.formInputVal
              }
              data.jsonVal.push(obj)
            }
          } else {
            const obj = {
              formFieldId: item.id,
              formInputVal: item.formInputVal
            }
            data.jsonVal.push(obj)
          }
        })
        for (let i = 0; i < data.jsonVal.length; i++) {
          if (!data.jsonVal[i].formInputVal || !data.jsonVal[i].formFieldId) {
            data.jsonVal.splice(i, 1)
            i--
          }
        }
        data.jsonVal = JSON.stringify(data.jsonVal)
        saveInputFormVal(data).then(res => {
          if (res.code == 0) {
            this.$router.push({
              path: '/marketing/promotionSuccess?text=' + this.formThanksNote
            })
          } else {
            this.$message({
              message: res.msg,
              type: res.code == 0 ? 'success' : 'error'
            })
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '请阅读条款'
        })
      }
    },
    /* 离开页面时发送 */
    timesClosePage() {
      home.timesClosePage(this.imgAccessId).then((res) => { })
    },
    goPage(e) {
      const item = JSON.parse(e.extend)
      if (!item) {
        return
      }
      /*  1直播  2 点播  7 音频 4 图文  10 面授  6 课程方向 */
      if (item.types === 0) {
        if (item.productLine == 3) {
          const routeUrl = this.$router.resolve({
            path: '/VIP/VIPClassxq',
            query: {
              id: item.classId
            }
          })
          window.open(routeUrl.href, '_blank')
        } else {
          const routeUrl = this.$router.resolve({
            path: '/typeclassxq',
            query: {
              id: item.classId
            }
          })
          window.open(routeUrl.href, '_blank')
        }
      } else if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: '/zhiboclassxq',
          query: {
            id: item.classId
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: '/videoxq',
          query: {
            id: item.classId
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 7) {
        const routeUrl = this.$router.resolve({
          path: '/audioCouser/index',
          query: {
            id: item.classId
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: '/imgCouser/index',
          query: {
            id: item.classId
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 10) {
        const routeUrl = this.$router.resolve({
          path: '/faceCouser/index',
          query: {
            id: item.classId
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    toShare(path) {
      window.open(path, '_blank')
    },
    openSharePopup() {
      this.sharePopup = true
    },
    closeSharePopup() {
      this.sharePopup = false
    },
    getDnsOneByTenantId() {
      getDnsOneByTenantId(getDomain().eId).then(res => {
        this.mUrl = res.mDuliDomain ? res.mDuliDomain : res.useMDomain
      })
    },

    // 登录相关
    handleClose() {
      this.newloginKey += 1
      if (this.cacheVal == 'mustBack') {
        this.$router.go(-1)
      }
      this.showLogin = false
    },
    handleCloseForgot() {
      this.forgotKey += 1
      this.showForgotDialog = false
    },
    handleCloseRegister() {
      this.registerKey += 1
      this.showRegister = false
    },
    handleShowLogin(bol) {
      this.showLogin = bol
      this.showRegister = false
    },
    dealForgot() {
      this.showForgotDialog = false
      this.showLogin = true
    },
    toHome() {
      this.cacheVal = ''
      this.showLogin = false
    },
    toRegister() {
      this.cacheVal = ''
      this.showLogin = false
      this.showRegister = true
    },
    showForgot() {
      this.showForgotDialog = true
      this.showLogin = false
    }
  }
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 750px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 40px 0;

  .multyName {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: PingFang SC-Bold;
  }

  .graphicsItem {
    margin: 12px 0;

    .knowproduct {
      width: 750px;
      height: 108px;
      display: flex;
      cursor: pointer;

      .knowproductImg {
        width: 192px;
        height: 108px;
        border-radius: 8px;
        margin-right: 12px;
      }

      .textContent {
        // width: 100%;
        width: 1;
        overflow: hidden;

        .titleText {
          font-size: 18px;
          color: #333333;
          // margin-bottom: 8px;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tipsText {
          font-size: 11px;
          color: #9fa4ad;
          // margin-bottom: 39px;
        }

        .priceBox {
          color: #ff5e51;

          .symbol {
            font-size: 14px;
          }

          .num {
            font-size: 24px;
          }
        }
      }
    }

    .videoBox {
      width: 750px;
      height: 420px;

      .video {
        width: 100%;
        height: 100%;
      }
    }

    .tecaherBox {
      display: flex;

      .tecaherImg {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 12px;
      }

      .teacherDetail {
        color: #333333;
        font-size: 14px;

        .teacherName {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
    }

    .formItem {
      margin-bottom: 24px;

      .itemTitle {
        font-size: 18px;
        color: #333333;
        font-weight: 500;
        margin-bottom: 16px;

        .require {
          display: inline-block;
          padding-right: 2rpx;
          color: #FF5E51;
        }
      }

      .contentBox {
        .select {
          width: 337px;
        }
      }
    }
  }

  .submitBox {
    position: relative;

    .submitBtn {
      width: 750px;
      height: 50px;
      background-color: #2a7af2;
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      line-height: 50px;
      margin-bottom: 25px;
      cursor: pointer;
    }

    .shareBox {
      display: flex;
      color: #2a7af2;
      align-items: center;
      cursor: pointer;
    }

    .text {
      font-size: 14px;
      margin-left: 8px;
    }

    .shareDialog {
      width: 360px;
      height: 230px;
      position: absolute;
      bottom: -200px;
      left: 100px;

      .shareContent {
        .shareImg {
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
          cursor: pointer;

          .shareImgItem {
            width: 40px;
            height: 40px;
          }
        }

        .urlBox {
          .urlInput {
            width: 60%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.qrcode_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  .qrcode {
    margin-bottom: 20px;
  }
}
</style>
